import { Button, Input, Box, Stack } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const AddQuestions = () => {
  const navigate = useNavigate();

  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState({}); // manages which questions are being edited

  // get token, current meeting and current section
  const token = localStorage.getItem('idToken');
  const currentMeeting = JSON.parse(localStorage.getItem('currentMeeting')) || {};
  const currentSection = JSON.parse(localStorage.getItem('currentSection')) || {};

  const { t } = useTranslation();

  // Function to fetch questions when component mounts
  useEffect(() => {
    fetchQuestions();
  }, []);

  const fetchQuestions = async () => {
    
    const postData = {
      meeting: currentMeeting,
      section: currentSection
    }

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/get_questions`, 
      postData,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    console.log(response.data.questions);

    setQuestions(response.data.questions);
  };

  const handleAddQuestion = async () => {
    setLoading(true);

    const postData = {
      meeting: currentMeeting,
      section: currentSection,
      question: {
        'question': newQuestion
      }
    }

    await axios.post(`${process.env.REACT_APP_API_URL}/add_question`, 
      postData,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    fetchQuestions();
    setNewQuestion('');
    setLoading(false);
  };

  const handleEditQuestion = async (index, questionText) => {
    // send request to update question
    console.log(index)
    const postData = {
      meeting: currentMeeting,
      section: currentSection,
      question: {
        'index': index,
        'question': questionText
      }
    }

    await axios.post(`${process.env.REACT_APP_API_URL}/update_question`, 
      postData,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    // re-fetch questions to reflect changes
    fetchQuestions();

    // exit edit mode
    setEditMode(prevState => ({...prevState, [index]: false}));
  };

  const handleBack = () => {
    navigate('/add-sections');
  };

  return (
    <Stack spacing={4}>
      {questions.map((question, index) => (
        <Box key={question.index}>
          {editMode[question.index] ? 
            <Input
              defaultValue={question.question}
              onBlur={(e) => handleEditQuestion(question.index, e.target.value)}
            /> :
            question.question
          }
          <Button onClick={() => setEditMode(prevState => ({...prevState, [question.index]: !prevState[question.index]}))}>
            {editMode[question.index] ? 'Save' : 'Edit'} {t('Question')}
          </Button>
        </Box>
      ))}
      <Input
        placeholder="Add question"
        value={newQuestion}
        onChange={(e) => setNewQuestion(e.target.value)}
        isDisabled={isLoading}
      />
      <Button onClick={handleAddQuestion} isDisabled={isLoading}>
        {t('Add Question')}
      </Button>
      <Button onClick={handleBack}>{t('Back to Sections')}</Button>
    </Stack>
  );
};

export default AddQuestions;