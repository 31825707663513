import React, { useState, useEffect, useRef } from 'react';
import {
  Box, RadioGroup, Radio, Text, Input, Button, HStack, Spacer, Flex, useToast, Spinner, VStack
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import HeaderBar from './components/HeaderBar';

const AnswerQuestion = () => {
  const [questions, setQuestions] = useState([]);
  const [currentTopicIndex, setCurrentTopicIndex] = useState(Number(localStorage.getItem('currentTopicIndex') || '0'));
  const [isLoading, setIsLoading] = useState(true);
  const [boxHeight, setBoxHeight] = useState(400);
  const [boxWidth, setBoxWidth] = useState(750);
  const [answerState, setAnswerState] = useState(JSON.parse(localStorage.getItem('answerState')) || {});
  const boxRef = useRef(null);
  const idToken = localStorage.getItem('idToken');
  const currentMeeting = JSON.parse(localStorage.getItem('currentMeeting') || '{}');
  const logoLink = localStorage.getItem('logoLink');
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchQuestion = async () => {
      try {
        const currentTopic = currentMeeting.topics[currentTopicIndex]
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/get_questions_by_topic?topic=${currentTopic}`,
        currentMeeting,
        {
            headers: { 'Authorization': `Bearer ${idToken}` }
        }
        );
        setQuestions(response.data);

        const initialAnswerState = {};
        const storedAnswerState = JSON.parse(localStorage.getItem('answerState')) || {};
        console.log("as1", answerState);
        console.log("as2", storedAnswerState);
        // ensure answer state has initial null values for the questions on the page
        response.data.forEach(question => {
          if (!storedAnswerState[question.index]) {
            storedAnswerState[question.index] = { score: null, answer: '' };
          }
        });
        console.log("as3", storedAnswerState);
        localStorage.setItem('answerState', JSON.stringify(storedAnswerState));
        setAnswerState(storedAnswerState);
      } catch (error) {
        console.error('Error fetching question', error);
      }
    };

    setIsLoading(true);
    fetchQuestion().then(() => {
      setIsLoading(false);
    });
  }, [currentTopicIndex]);

  const handleSave = async (showToast) => {
    console.log("answer state", answerState);
    localStorage.setItem('answerState', JSON.stringify(answerState));
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/update_answer_state`,
          {
              meeting: currentMeeting,
              answer_state: answerState
          },
          {
              headers: { 'Authorization': `Bearer ${idToken}` }
          }
      );

      if (showToast) {
        toast({
          title: "Answers saved.",
          status: "success",
          duration: 1500,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log("error saving answer state:", error)
    }
  };

  const handleNavigation = (direction) => {
    handleSave(false);
    if (boxRef.current) {
      setBoxHeight(boxRef.current.offsetHeight);
      setBoxWidth(boxRef.current.offsetWidth);
      console.log(boxHeight)
    }
    if (direction === 'next') {
      if (currentTopicIndex < currentMeeting.topics.length - 1) {
        setCurrentTopicIndex(currentTopicIndex + 1);
      } else {
        navigate('/submit-answer');
      }
    } else if (direction === 'back' && currentTopicIndex > 0) {
      setCurrentTopicIndex(currentTopicIndex - 1);
    }
  };

  const handleBackToOverview = () => {
    navigate('/meeting-overview');
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="flex-start" minHeight="100vh" px={5} pt={5} w="100%" mx="auto">
      <HeaderBar
        title={`${currentMeeting.company} - ${currentMeeting.title}`}
        imageLink={logoLink}
      />
      <Flex width="100%">
        {/* Sidebar for topics */}
        <VStack align="start" spacing={1} p={5} minW="200px" maxW="300px" borderWidth="1px" borderRadius="md" mr={5}>
          <Text fontSize="lg" fontWeight="bold" mb={4}>{t('Navigate to Topics')}</Text>
          
          {currentMeeting.topics.map((topic, index) => (
            <Box 
              w="100%"  // Full width
              p={3} 
              borderRadius="md" 
              bg={currentTopicIndex === index ? 'blue.500' : 'transparent'} 
              color={currentTopicIndex === index ? 'white' : 'black'} 
              _hover={{ bg: 'gray.200', cursor: 'pointer' }} 
              key={index}
              onClick={() => {
                setCurrentTopicIndex(index);
                handleSave(false);
              }}
              isTruncated
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {topic}
            </Box>
          ))}
          <Spacer />
          <Button variant="ghost" w="100%" onClick={handleBackToOverview}>{t('Back to Overview')}</Button>
        </VStack>

        {/* Main Content */}
        <Box flex="1">

          <Box flexDirection="column" width="80%" alignItems="center" justifyContent="center" flexGrow={1} pt="12px" pb={5}>
          { /* display spinner when loading */ 
              isLoading ? 
              <Box height={boxHeight} width={boxWidth} display="flex" flexDirection="column" alignItems="center" justifyContent="center" p={5} shadow="md" borderWidth="1px" borderRadius="md">
                <Spinner size="xl" />
              </Box>
              :
              <Box p={5} shadow="md" borderWidth="1px" borderRadius="md" width="100%">
                <HStack width="100%" px={5} pb={5}>
                  <Text mb={4} fontSize="xl">{t('Topic:')} {currentMeeting.topics[currentTopicIndex]}</Text>
                  <Spacer />
                  <Button onClick={() => {handleSave(true)}}>{t('Save')}</Button>
                </HStack>
                {questions.map((question, index) => (
                  <Box key={index} mb={5}>
                    <Text mb={4}>{t('Question')} {index + 1}</Text>
                    <Text mb={4}>{question.text}</Text>
                    { /* dont display the numerical score if its an open ended question */
                      !(question.open_ended) &&
                      <RadioGroup 
                        onChange={(val) => setAnswerState(prev => ({ ...prev, [question.index]: { ...prev[question.index], score: val } }))} 
                        value={Number(answerState[question.index]?.score)} 
                        paddingY="24px"
                      >
                        <HStack spacing={5} px="12">
                          {["Strongly Disagree (1)", "Disagree (2)", "Neutral (3)", "Agree (4)", "Strongly Agree (5)"].map((label, idx) => (
                            <Flex direction="column" alignItems="center" w="20%" key={idx}>
                              <Radio size="lg" value={idx + 1} />
                              <Box fontSize="sm" mt={2}>{label}</Box>
                            </Flex>
                          ))}
                        </HStack>
                      </RadioGroup> 
                    }
                    <Input 
                      mt={4} 
                      value={answerState[question.index]?.answer || ''} 
                      onChange={e => setAnswerState(prev => ({ ...prev, [question.index]: { ...prev[question.index], answer: e.target.value } }))} 
                      placeholder="Write your justification here..."
                    />
                  </Box>
                ))}
              </Box>
          }
          </Box>
          
          <HStack spacing={4} mb={5} width="80%" px={5}>
              {currentTopicIndex > 0 && (
                  <Button variant="outline" onClick={() => handleNavigation('back')}>
                      {t('Back')}
                  </Button>
              )}
              <Spacer />
              <Button colorScheme="blue" onClick={() => handleNavigation('next')}>
                  {currentTopicIndex === currentMeeting.topics.length - 1 ? 'Submit' : 'Next'}
              </Button>
          </HStack>
          
        </Box>
      </Flex>
    </Box>
  );
};

export default AnswerQuestion;
