// AddMeeting.js
import { Button, Input, Textarea, Box, useToast, Text, Heading, Image, HStack, Spacer, Flex, useTheme } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import HeaderBar from "./components/HeaderBar";

const AddMeeting = () => {
    const navigate = useNavigate();
    const toast = useToast();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [date, setDate] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [logoFile, setLogoFile] = useState();
    const [logoURL, setLogoURL] = useState();
    const idToken = localStorage.getItem('idToken');
    const theme = useTheme();
    const bgColor = theme.colors.background;
    const { t } = useTranslation();
  

  const handleBack = () => {
    navigate("/");
  };

  const handleNext = async () => {
    // we first create our form data object to store our meeting and image objects
    const formData = new FormData();

    // add our image file to the form data
    formData.append('logo', logoFile)

    // now we append our meeting info as a meeting object
    const meetingData = {
      title: title,
      description: description,
      date: new Date(date).getTime(), // converting to timestamp
      company: companyName
    };
    const jsonMeetingData = JSON.stringify(meetingData);
    formData.append('meeting', jsonMeetingData)

      try {
        await axios.post(
            `${process.env.REACT_APP_API_URL}/create_meeting`, 
            formData,
            { 
                headers: {
                  //'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ${idToken}`
                } 
            }
        );
        localStorage.setItem("currentMeeting", jsonMeetingData)
        toast({
          title: "Meeting created.",
          description: "We've created your new meeting.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        navigate('/pick-questions');
      } catch (error) {
        console.log(error)
        toast({
          title: "An error occurred.",
          description: "Unable to create the meeting.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
  };

  const handleImageInput = (event) => {
    const file = event.target.files[0];
    
    if (file && file.type === 'image/png') {
        setLogoURL(URL.createObjectURL(file));
        setLogoFile(file);
    } else {
      toast({
        title: "Please upload only .PNG images.",
        description: "You can convert images at svgtopng.com",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" minHeight="100%" px={5} pt={5} w="100%" mx="auto">
      <Box width="100%" mb={0} position="sticky" top="0" zIndex="1000" bg={bgColor}>
          <HeaderBar
              title="Create New Meeting"
              imageLink={null}
          >
              <Flex marginRight="14px" justifyContent="center" alignItems="center">
                  <Button onClick={handleBack}>{t('Back')}</Button>
              </Flex>
          </HeaderBar>
      </Box>
      <Box width="80%" mx="auto">
        <Heading size='md' margin="12px">
          {t('Title')}
        </Heading>
        <Input
          placeholder="Meeting title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          marginX="12px" marginBottom="12px"
        />
        <Heading size='md' margin="12px">
          {t('Description')}
        </Heading>
        <Textarea
          placeholder="Meeting description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          marginX="12px" marginBottom="12px"
        />
        <Heading size='md' margin="12px">
          {t('Due Date')}
        </Heading>
        <Flex marginX="12px" marginBottom="24px">
          <DatePicker selected={date} onChange={(date) => setDate(date)}  />
        </Flex>
        <Heading size='md' margin="12px">
          {t('Company')}
        </Heading>
        <Input
          placeholder="Company name"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          marginX="12px" marginBottom="12px"
        />
        <Heading size='md' margin="12px">
          {t('Upload Logo (.png files only)')}
        </Heading>
        <Input type="file" accept=".png" onChange={handleImageInput} marginX="12px" marginBottom="12px" />
        <Image src={logoURL} />
        <HStack maxWidth="100px" maxHeight="100px" margin="12px">
        <Button minWidth="100px" colorScheme="teal" variant="solid" onClick={handleBack}>
          {t('Back')}
        </Button>
        <Spacer />
        <Button minWidth="100px" colorScheme="teal" variant="solid" onClick={handleNext}>
          {t('Next')}
        </Button>
        </HStack>
      </Box>
    </Box>
  );
};

export default AddMeeting;
