import React, { useState, useEffect } from "react";
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useDisclosure, Input, Alert, AlertIcon, Box, Flex, Text } from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

function AddSections() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isAddingSection, setIsAddingSection] = useState(false);
    const [sectionName, setSectionName] = useState('');
    const [sections, setSections] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const { t } = useTranslation();
    
    const currentMeeting = JSON.parse(localStorage.getItem('currentMeeting')) || {};
    const token = localStorage.getItem('idToken');

    useEffect(() => {
        // assuming you already have an endpoint to get sections of a meeting
        axios.get(`${process.env.REACT_APP_API_URL}/get_sections`,
        {
          params: {
            title: currentMeeting.title,
            date: currentMeeting.date
          },
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then(response => {
            setSections(response.data.sections);
        })
        .catch(err => console.error(err));
    }, []);

    const handleBack = () => {
        navigate('/');
    };

    const openAddSection = () => {
        onOpen();
        setIsAddingSection(true);
    }

    const closeAddSection = () => {
        onClose();
        setIsAddingSection(false);
    }

    const handleInputChange = (event) => {
        setSectionName(event.target.value);
    }

    // TODO: move this into the components file
    const handleEdit = (section) => {
      // Store current section in local storage
      localStorage.setItem('currentSection', JSON.stringify(section));

      // Redirect to the AddQuestions page
      navigate('/add-questions');
    }

    // TODO: move this into the components file
    const SectionCard = ({ section }) => {
      return (
        <Box borderWidth="1px" borderRadius="lg" overflow="hidden" margin="1em" p="1em">
          <Flex justify="space-between">
            <Text>{section.title}</Text>
            <Button onClick={()=>handleEdit(section)}>{t('Edit')}</Button>
          </Flex>
        </Box>
      )
    };

    const handleAddSection = () => {
        if (sections.includes(sectionName)) {
            setErrorMessage('Section titles must be unique.');
            return;
        }

        const sectionData = {
            title: sectionName,
        };

        const currentMeeting = JSON.parse(localStorage.getItem('currentMeeting')) || {};

        const postData = {
          meeting: currentMeeting,
          section: sectionData
        }
      
        axios.post(`${process.env.REACT_APP_API_URL}/add_section`, 
            postData,
            {
              headers: {
                  Authorization: `Bearer ${token}`
              }
            }
          )
        .then(response => {
            localStorage.setItem('currentSection', JSON.stringify({ title: sectionName }));
            navigate('/add-questions');
        })
        .catch(err => console.error(err));
    }

    return (
      <div>
        <Button onClick={handleBack}>{t('Back to Home')}</Button>
        {/* Map through sections state to display each section as a card */}
        {sections.map((section, index) => (
          <SectionCard key={index} section={section} />
        ))}
        <Button colorScheme="teal" onClick={openAddSection}>
          {t('Add Section')}
        </Button>
        <Modal isOpen={isOpen} onClose={closeAddSection}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{t('Add a new section')}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Input placeholder="Section name" value={sectionName} onChange={handleInputChange} />
              {errorMessage && <Alert status="error" mt={4}><AlertIcon />{errorMessage}</Alert>}
              <Button colorScheme="teal" mt={4} onClick={handleAddSection}>
                {t('Add Questions')}
              </Button>
            </ModalBody>
          </ModalContent>
        </Modal>
      </div>
    );
}

export default AddSections;