import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    brand: {
      500: "#3b82f6",
    },
    primary: {
      500: "#3535ef",
    },
    secondary: {
      500: "#f35332",
    },
    success: {
      500: "#198754"
    },
    info: {
      500: "#0dcaf0",
    },
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: "lg",
      },
      variants: {
        ghost: {
          bg: "transparent",          // Make sure the background is transparent
          border: "none",             // Remove any borders
          _hover: {                   // Hover state customization
            bg: "transparent",        // Make sure hover background remains transparent
            textDecoration: "none",   // Remove underlines or other text decorations
          },
          _active: {                  // Active (pressed) state customization
            bg: "transparent",        // Ensure background remains transparent
          },
        },
        link: {
          bg: "transparent",          // Make sure the background is transparent
          border: "none",             // Remove any borders
          _hover: {                   // Hover state customization
            textDecoration: "underline",  // Typically, link buttons have an underline on hover
            bg: "transparent",        // Make sure hover background remains transparent
          },
          _active: {                  // Active (pressed) state customization
            bg: "transparent",        // Ensure background remains transparent
          },
        },
      },
    },
    Input: {
      baseStyle: {
        borderRadius: "lg",
        focusBorderColor: "brand.500",
      },
    },
    Box: {
      baseStyle: {
        "bg": "#DBE2EF"
      }
    }
  },
  styles: {
    global: {
      "html, body": {
        bg: "#F9F7F7",  // This sets the default background color
        color: "#112D4E",  // This sets the default text color (optional)
      },
    },
  },
});

export default theme;
