import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, 
  Button, 
  Input, 
  Text,
  VStack, 
  HStack,
  Heading, 
  useToast, 
  useDisclosure, 
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalHeader, 
  ModalFooter, 
  ModalBody, 
  ModalCloseButton,
  AlertDialog, 
  AlertDialogOverlay, 
  AlertDialogContent, 
  AlertDialogHeader, 
  AlertDialogBody, 
  AlertDialogFooter,
  Flex,
  Spacer,
  Select,
  Checkbox
} from '@chakra-ui/react';
import { EditIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const EditQuestionBank = () => {
  const [questions, setQuestions] = useState({});
  const [editingQuestion, setEditingQuestion] = useState(null);
  const [searchFilter, setSearchFilter] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [questionToDelete, setQuestionToDelete] = useState(null);
  const [topics, setTopics] = useState([]);
  const toast = useToast();
  const newQuestionRef = useRef();  // Initialize a new ref
  const cancelRef = useRef();
  const idToken = localStorage.getItem('idToken');
  const navigate = useNavigate();

  // // filtered version of the questions
  // const filteredQuestions = Object.entries(questions)
  // .filter(([questionNumber, questionText]) => 
  //   questionText.toLowerCase().includes(searchFilter.toLowerCase())
  // );

  useEffect(() => {
    const fetchQuestions = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/get_question_bank`, {
                headers: {
                    Authorization: `Bearer ${idToken}`
                }
            })
            // account for legacy questions not having the open_ended
            // const questionsWithOpenEnded = Object.entries(response.data.questions).reduce((acc, [key, value]) => {
            //     acc[key] = { ...value, open_ended: value.open_ended ?? false };
            //     return acc;
            // }, {});
            setQuestions(response.data.questions);
            const uniqueTopics = [...new Set(Object.values(response.data.questions).map(question => question.topic))];
            setTopics(uniqueTopics);
        } catch (error) {
            console.log("Error fetching question bank:", error)
        }
    };
    
    fetchQuestions();
  }, []);

  const handleEdit = (questionNumber) => {
    // Save any question that's being edited when another edit button is clicked
    if (editingQuestion) {
      handleSave(editingQuestion);
    }
    setEditingQuestion(questionNumber);
  };

  const onClose = () => setIsOpen(false);

  const handleDelete = (questionNumber) => {
    setQuestionToDelete(questionNumber);
    setIsOpen(true);
  };
  
  const confirmDelete = () => {
    // delete the question in a temp version of the question bank
    const updatedQuestions = { ...questions };
    delete updatedQuestions[questionToDelete];

    // reindex the questions
    const reindexedQuestions = Object.values(updatedQuestions)
      .reduce((acc, questionText, index) => {
        acc[index + 1] = questionText;
        return acc;
      }, {});

    // update the question bank variable
    setQuestions(reindexedQuestions)

    setIsOpen(false);
  };

  const handleFinishEditing = (questionNumber, editedText, selectedTopic, openEnded) => {
    if (!topics.includes(selectedTopic)) {
      setTopics([...topics, selectedTopic]); // Add the new topic to the list of topics if it's new
    }

    // save changes locally
    const updatedQuestions = { ...questions };
    updatedQuestions[questionNumber] = { question: editedText, topic: selectedTopic, open_ended: openEnded };
    setQuestions(updatedQuestions);
    setEditingQuestion(null);
  }

  const handleSave = async (questionNumber) => {
    setEditingQuestion(null);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/set_question_bank`, 
        questions,
        { 
            headers: {
              Authorization: `Bearer ${idToken}`
            } 
        }
      );
    } catch (error) {
      console.log("error submitting new questions:", error);
    }

    navigate('/')
  };

  const handleExitWithoutSaving = () => {
    navigate('/');
  }

  const handleAddNewQuestion = () => {
    // Find the next question number (assuming current questions are sequentially numbered)
    const newQuestionNumber = Object.keys(questions).length + 1;
  
    // Update the questions state to include the new question
    setQuestions(prevQuestions => ({
      ...prevQuestions,
      [newQuestionNumber]: {question: '', topic: topics[0], open_ended: false}  // Empty string as the new question's placeholder text
    }));
  
    // Use a ref to scroll to the new question. I'll explain how to set this up in step 2.
    setTimeout(() => {
      newQuestionRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  // Similarly, add handlers for other operations like deleting a question, searching, etc.

  const QuestionCard = ({ questionNumber, question, onEdit, onDelete }) => {
    return (
      <Box width="100%">
        <HStack width="100%">
          <VStack align="start" spacing={2}>
            <Text fontWeight="bold">{`Q${questionNumber}: ${question.question}`}</Text>
            <Text color="gray.500">{`Topic: ${question.topic}`}</Text>
            <Text color="gray.500">{(question.open_ended ?? false) ? "Open Ended" : "Not Open Ended"}</Text>
          </VStack>
          <Spacer />
          <Button onClick={() => onEdit(questionNumber)}><EditIcon /></Button>
          <Button onClick={() => onDelete(questionNumber)}><CloseIcon /></Button>
        </HStack>
      </Box>
    );
  };
  
  const EditQuestionCard = ({ questionNumber, question, onSave }) => {
    const [editedText, setEditedText] = useState(question.question);
    const [selectedTopic, setSelectedTopic] = useState(question.topic);
    const [openEnded, setOpenEnded] = useState(question.open_ended ?? false);
    const [newTopic, setNewTopic] = useState('');
    const [isNewTopic, setIsNewTopic] = useState(false);

    const handleTopicChange = (e) => {
      if (e.target.value === "new-topic") {
        setIsNewTopic(true);
        setSelectedTopic('');
      } else {
        setIsNewTopic(false);
        setSelectedTopic(e.target.value);
      }
    };

    const handleBlurNewTopic = () => {
      if (newTopic && !topics.includes(newTopic)) {
        setSelectedTopic(newTopic); // Set new topic as selected when input loses focus and it's valid
        setIsNewTopic(false);
      }
    };
  
    return (
      <Box>
        <HStack width="100%">
          <Input value={editedText} onChange={(e) => setEditedText(e.target.value)} />
          {!isNewTopic ? (
            <Select value={selectedTopic} onChange={handleTopicChange} width="200px">
              {topics.map(topic => <option key={topic} value={topic}>{topic}</option>)}
              <option value="new-topic">Add new topic</option>
            </Select>
          ) : (
            <Input
              placeholder="Enter new topic"
              value={newTopic}
              onChange={(e) => setNewTopic(e.target.value)}
              onBlur={handleBlurNewTopic}
            />
          )}
          <Checkbox isChecked={openEnded} onChange={(e) => setOpenEnded(e.target.checked)}>
              Open Ended
          </Checkbox>
          <Button onClick={() => onSave(questionNumber, editedText, selectedTopic || newTopic, openEnded)}><CheckIcon /></Button>
        </HStack>
      </Box>
    );
  };
  
  const DeleteConfirmationDialog = ({ isOpen, onClose, onDelete }) => {
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Question</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete this question?
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={onDelete}>Delete</Button>
            <Button variant="ghost" onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  return (
    <Box width="80%" mx="auto">
      <HStack width="100%" paddingY="24px">
        <Input placeholder="Search for questions..." onChange={(e) => setSearchFilter(e.target.value)} />
        <Button onClick={handleAddNewQuestion}>Add New Question</Button>
      </HStack>
      <VStack width="100%" spacing={4}>
        {Object.entries(questions).map(([questionNumber, question], index) => (
          <Box width="100%" key={questionNumber} ref={index === Object.keys(questions).length - 1 ? newQuestionRef : undefined}>
            {editingQuestion === questionNumber ?
              <EditQuestionCard questionNumber={questionNumber} question={question} onSave={handleFinishEditing} /> :
              <QuestionCard questionNumber={questionNumber} question={question} onEdit={handleEdit} onDelete={() => {handleDelete(questionNumber)}} />
            }
          </Box>
        ))}
      </VStack>
      <HStack width="100%" paddingTop="24px" paddingBottom="12px">
        <Spacer />
        <Button onClick={handleSave} paddingRight="12px">Save</Button>
        <Button onClick={handleExitWithoutSaving}>Exit Without Saving</Button>
      </HStack>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Question
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this question? This action cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={confirmDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default EditQuestionBank;
