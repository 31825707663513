import React, { useState, useEffect } from 'react';
import { Flex, Box, Button, Heading, IconButton, Table, Thead, Tbody, Tr, Th, Td, Tooltip } from '@chakra-ui/react';
import { ArrowBackIcon, CopyIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import HeaderBar from './components/HeaderBar';

function ParticipantDetails() {
  const [loginDetails, setLoginDetails] = useState([]);
  const navigate = useNavigate();
  const idToken = localStorage.getItem('idToken');
  const currentMeeting = JSON.parse(localStorage.getItem('currentMeeting'));
  const { t } = useTranslation();

  useEffect(() => {
    const retrieveLoginDetails = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/get_participant_details`, 
        currentMeeting,
        {
          headers: { 'Authorization': `Bearer ${idToken}` }
        });
        console.log(response.data.login_details);
        setLoginDetails(response.data.login_details);
      } catch (error) {
        console.error('Error while loading participant details', error);
      } 
    };

    retrieveLoginDetails();
  }, []);

  const handleCopyLink = (link) => {
    navigator.clipboard.writeText(link);
    // Optionally, add feedback like a toast message
  };

  const handleBack = () => {
    navigate('/');
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="flex-end" minHeight="100%" px={5} pt={5} w="100%">
      <HeaderBar
        title={`${t('Participants for')} ${currentMeeting.company}`}
        imageLink={null}
      >
        <Flex marginRight="14px" justifyContent="center" alignItems="center">
          <Button onClick={handleBack}>{t('Back')}</Button>
        </Flex>
      </HeaderBar>
      <Table size="sm" width="2xl">
        <Thead>
          <Tr>
            <Th>Email</Th>
            <Th>{t('Login Link')}</Th>
            <Th>{t('Copy')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {loginDetails.map((ld, index) => (
            <Tr key={index}>
              <Td>{ld.email}</Td>
              <Td maxWidth="200px" isTruncated>
                <Tooltip label={ld.login_link} placement="top" hasArrow>
                  {ld.login_link}
                </Tooltip>
              </Td>
              <Td>
                <Button onClick={() => handleCopyLink(ld.login_link)} size="sm">
                  <CopyIcon />
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
}
   

export default ParticipantDetails;
