import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Center,
  HStack,
  Heading,
  Text,
  VStack,
  useToast
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import HeaderBar from './components/HeaderBar';
import { useTranslation } from 'react-i18next';


const SubmitAnswer = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionComplete, setSubmissionComplete] = useState(false);
  const [questionsLoading, setQuestionsLoading] = useState(true);
  const [questions, setQuestions] = useState({});
  const navigate = useNavigate();
  const currentMeeting = JSON.parse(localStorage.getItem('currentMeeting'));
  const idToken = localStorage.getItem('idToken');
  const answerState = JSON.parse(localStorage.getItem('answerState'));
  const logoLink = localStorage.getItem('logoLink');
  const toast = useToast();
  const { t } = useTranslation();

  useEffect(() => {
    // Fetch all questions on component mount
    const fetchQuestions = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/get_all_questions`,
            currentMeeting,
            {
                headers: { 'Authorization': `Bearer ${idToken}` }
            }
        );
        let questionList = response.data.questions
        let questionDict = {}
        for (let idx = 0; idx < questionList.length; idx++) {
          questionDict[questionList[idx]["index"]] = questionList[idx]
        }
        setQuestions(questionDict);
        setQuestionsLoading(false);
      } catch (error) {
        toast({
          title: "Error fetching questions.",
          status: "error",
          duration: 3000,
          isClosable: true
        });
      }
    };

    fetchQuestions();
  }, [toast]);

  const handleSubmit = async () => {

    for (let question_index = 1; question_index <= currentMeeting.numberOfQuestions; question_index++) {
      if (answerState[question_index]["score"] === null && !questions[question_index]["open_ended"]) {
        toast({
          title: t('Please answer all questions before submitting.'),
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return
      }
    }
    setIsSubmitting(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/submit_answers`, 
      {
        "meeting": currentMeeting,
        "answer_state": answerState
      },
      {
        headers: { 'Authorization': `Bearer ${idToken}` }
      });
    } catch (error) {
      console.error('Error submitting answers', error);
    } finally {
      setIsSubmitting(false);
      setSubmissionComplete(true);
    }
  }

  const handleBackToHome = () => {
    navigate('/');
  };

  const handleBack = () => {
    navigate('/answer-question');
  }

  return (
    <Box p={5}>
      <HeaderBar
        title={t('Evaluation Complete')}
        imageLink={logoLink}
      />
      <Center h="100vh" w="100%">
        <VStack spacing={6}>
          
          <Box
            p={5}
            borderWidth="1px"
            borderRadius="lg"
            shadow="md"
            w="100%"
            maxW="md"
          >
            <Text>
              {t('Welcome and closing - paragraph 1')} <br />
              <br />
              {t('Welcome and closing - paragraph 2')} <br />
            </Text>
          </Box>
          {
            submissionComplete ?
            <Button
              colorScheme="blue"
              size="lg"
              borderRadius="full"
              onClick={handleBackToHome}
            >
              {t('Back To Home')}
            </Button>
            :
            <HStack>
              <Button
                colorScheme="blue"
                size="lg"
                borderRadius="full"
                onClick={handleBack}
                isDisabled={isSubmitting}
              >
                {t('Back')}
              </Button>
              <Button
                colorScheme="blue"
                size="lg"
                borderRadius="full"
                onClick={handleSubmit}
                isLoading={isSubmitting}
                isDisabled={isSubmitting || questionsLoading}
              >
                {t('Submit')}
              </Button>
            </HStack>
          }
          {/* add box to fix spacing */}
          <Box height="60px"/>
        </VStack>
      </Center>
    </Box>
    
  );
};

export default SubmitAnswer;
