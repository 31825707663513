import { Box, Button, Input, VStack, HStack, Icon, Text, Flex, Heading, Select } from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusSquareIcon, CloseIcon } from '@chakra-ui/icons'; // Remember to install '@chakra-ui/icons' if you haven't
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import HeaderBar from './components/HeaderBar';

const AddParticipants = () => {
    const [participants, setParticipants] = useState([{ name: '', email: '', type: '' }]);
    const idToken = localStorage.getItem('idToken');
    const currentMeeting = JSON.parse(localStorage.getItem('currentMeeting') || '{}');
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleBackHome = () => {
        navigate('/');
    };

    const handleNext = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/add_participants`,
                {
                    meeting: currentMeeting,
                    participants: participants
                },
                {
                    headers: { 'Authorization': `Bearer ${idToken}` }
                }
            );
            console.log(response.data)
        } catch (error) {
            console.log("Error adding participants:", error);
        }

        navigate('/meeting-created');
    };

    const handleBack = async () => {
        navigate('/pick-questions');
    };

    const addParticipant = () => {
        setParticipants([...participants, { name: '', email: '', type: '' }]);
    };

    const removeParticipant = (index) => {
        const newParticipants = [...participants];
        newParticipants.splice(index, 1);
        setParticipants(newParticipants);
    };

    const updateParticipant = (index, field, value) => {
        const newParticipants = [...participants];
        newParticipants[index][field] = value;
        setParticipants(newParticipants);
    };

    return (
        <Box width="100%" mx="auto" p={4} display="flex" flexDirection="column" height="100vh">
            <Box width="100%">
                <HeaderBar
                    title={`${t('Add Participants for')} ${currentMeeting.company}`}
                    imageLink={null}
                >
                    <Flex marginRight="14px" justifyContent="center" alignItems="center">
                        <Button onClick={handleBackHome}>{t('Back')}</Button>
                    </Flex>
                </HeaderBar>
            </Box>

            <VStack width="80%" mt={5} mx="auto" spacing={4} flex="1">
                <HStack key="header" spacing={4} w="100%">
                    <Heading size="md" flex="1">{t('Full Name')}</Heading>
                    <Heading size="md" flex="1">{t('Email')}</Heading>
                    <Heading size="md" flex="1">{t('Type')}</Heading>
                    <Button visibility="hidden">
                        <Icon as={CloseIcon} />
                    </Button>
                </HStack>

                {participants.map((participant, index) => (
                    <HStack key={index} spacing={4} w="100%">
                        <Input
                            placeholder="Name"
                            value={participant.name}
                            onChange={(e) => updateParticipant(index, 'name', e.target.value)}
                            flex="1"
                        />
                        <Input
                            placeholder="Email"
                            type="email"
                            value={participant.email}
                            onChange={(e) => updateParticipant(index, 'email', e.target.value)}
                            flex="1"
                        />
                        <Select
                            placeholder="Select type"
                            value={participant.type}
                            onChange={(e) => updateParticipant(index, 'type', e.target.value)}
                            flex="1"
                        >
                            <option value="SB">SB</option>
                            <option value="EB">EB</option>
                            <option value="Other">Other</option>
                        </Select>
                        <Button onClick={() => removeParticipant(index)}>
                            <Icon as={CloseIcon} />
                        </Button>
                    </HStack>
                ))}
                <Button width="100%" mt={4} onClick={addParticipant}>
                    <Icon as={PlusSquareIcon} />
                </Button>
            </VStack>

            <Box mx="auto" width="80%" display="flex" justifyContent="space-between" mt="auto">
                <Button onClick={handleBack}>{t('Back')}</Button>
                <Text>{participants.length} {t('participants')}</Text>
                <Button onClick={handleNext}>{t('Next')}</Button>
            </Box>
        </Box>
    );
}

export default AddParticipants;
