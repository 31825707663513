// Login.js

import React, { useState, history } from 'react';
import { Box, Button, Input, FormControl, FormLabel, useToast, VStack, Heading, Container, Text, Flex } from '@chakra-ui/react';
import { app, auth } from './firebase';  // Adjust path as necessary
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { sendPasswordResetEmail } from 'firebase/auth';

function SetPassword() {
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setEmailSent(true);
    } catch (error) {
      console.log(error)
    }
  }

  const handleBackToLogin = () => {
    navigate('/login')
  }

  return (
    <Flex alignItems="center" justifyContent="center" height="100vh" width="100%">
      { emailSent ?
      <VStack 
        spacing={6} 
        align="center" 
        padding={10} 
        borderRadius="xl"
        borderWidth={2}
        borderColor="blue.500"
        w="full" maxW="400px"
      >
        <Heading size="lg" color="blue.500">{t('Email Sent!')}</Heading>
        <Text>{t('Check your inbox for an email with a password reset link.')}</Text>
        <Button 
          mt={4} 
          colorScheme="blue" 
          type="submit" 
          borderRadius="lg"
          size="lg"
          w="full"
          onClick={handleBackToLogin}
        >
          {t('Back to Login')}
        </Button>
      </VStack>
      :
      <VStack 
        spacing={6} 
        align="center" 
        padding={10} 
        borderRadius="xl"
        borderWidth={2}
        borderColor="blue.500"
        w="full" maxW="400px"
      >
        <Heading size="lg" color="blue.500">{t('Set/Reset Password')}</Heading>
        <form onSubmit={handleSubmit} w="full">
          <FormControl id="username">
            <FormLabel>Email</FormLabel>
            <Input 
              type="email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              borderRadius="lg"
              focusBorderColor="blue.500"
              isRequired
            />
          </FormControl>
          <Button 
            mt={4} 
            colorScheme="blue" 
            type="submit" 
            borderRadius="lg"
            size="lg"
            w="full"
          >
            {t('Send Password Reset Link')}
          </Button>
        </form>
      </VStack>
      }
    </Flex>
  );
}

export default SetPassword;