// Login.js

import React, { useState, history, useEffect } from 'react';
import { Box, Button, Input, FormControl, FormLabel, useToast, VStack, Heading, Container, Flex } from '@chakra-ui/react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { app, auth } from './firebase';  // Adjust path as necessary
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Login({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const toast = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation();
  
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const pwd = urlParams.get('password');

    if (email && pwd) {
      setUsername(email);
      setPassword(pwd);
      handleSubmit(null, email, pwd); // Auto-submit if parameters are found
    }
  }, []);

  const handleSubmit = async (e, _email=username, _password=password) => {
    if (e) {
      e.preventDefault();
    }

    try {
      const userCredential = await signInWithEmailAndPassword(auth, _email, _password);
      const user = userCredential.user;
      console.log(user);

      toast({
        title: "Logged In.",
        description: "You have successfully logged in.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      const idToken = await user.getIdToken();
      localStorage.setItem('idToken', idToken); // Store the ID token in local storage

      console.log(process.env.REACT_APP_API_URL);

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/user_type`, {
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      });
      console.log(response);
      localStorage.setItem("admin", JSON.stringify(response.data.admin));
      // history.push("/home");

      onLogin();  // Call the onLogin function when login is successful
    } catch (error) {
      console.log(error)
      toast({
        title: "An error occurred.",
        description: "Unable to log in. Check your credentials.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }

  const handleForgotPassword = () => {
    navigate('/set-password');
  }

  return (
    <Flex minH="100vh" alignItems="center" justifyContent="center">
      <Container centerContent>
        <VStack 
          spacing={6} 
          align="center" 
          padding={10} 
          borderRadius="xl"
          borderWidth={2}
          borderColor="blue.500"
          w="full" maxW="400px"
        >
          <Heading size="lg" color="blue.500">{t('Log in')}</Heading>
          <form onSubmit={handleSubmit} w="full">
            <FormControl id="username">
              <FormLabel>{t('Username')}</FormLabel>
              <Input 
                type="email" 
                value={username} 
                onChange={(e) => setUsername(e.target.value)} 
                borderRadius="lg"
                focusBorderColor="blue.500"
                isRequired
              />
            </FormControl>
            <FormControl id="password">
              <FormLabel>{t('Password')}</FormLabel>
              <Input 
                type="password" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                borderRadius="lg"
                focusBorderColor="blue.500"
                isRequired
              />
            </FormControl>
            <Button 
              mt={4} 
              colorScheme="blue" 
              type="submit" 
              borderRadius="lg"
              size="lg"
              w="full"
            >
              {t('Log in')}
            </Button>
          </form>
          <Button variant="link" onClick={handleForgotPassword}>
            {t('Forgot Password?')}
          </Button>
        </VStack>
      </Container>
    </Flex>
  );
}

export default Login;