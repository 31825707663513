import React, { useEffect, useState, useRef } from 'react';
import { Box, Button, Flex, Image, Spacer, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { storage as firebaseStorage } from './firebase';
import { ref, getDownloadURL } from 'firebase/storage';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import HeaderBar from './components/HeaderBar';


// TODO: make it accept a current meeting instead of loading it here
// function WelcomeScreen({ currentMeeting })
function WelcomeScreen() {
    const [currentMeeting, setCurrentMeeting] = useState({});
    const [imageLink, setImageLink] = useState(localStorage.getItem('logoLink'));
    const [isLoading, setIsLoading] = useState(true);
    const [username, setUsername] = useState(''); 
    const idToken = localStorage.getItem('idToken');
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleGoToEvaluation = () => {
        // TODO: ensure this cannot be pressed until the meeting is loaded
        navigate("/meeting-overview");
    }

    useEffect(() => {
        const fetchMeeting = async () => {
            try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/get_most_recent_meeting`, {
                headers: { 'Authorization': `Bearer ${idToken}` }
            });
            localStorage.setItem("currentMeeting", JSON.stringify(response.data.meeting));
            setCurrentMeeting(response.data.meeting);
            return response.data.meeting;
            } catch (error) {
            console.error('Error fetching meetings', error);
            }
        };

        const getDownloadLink = async (meeting) => {
          try {
            const imageRef = ref(firebaseStorage, meeting.logo_url);
            const response = await getDownloadURL(imageRef);
            setImageLink(response);
            localStorage.setItem('logoLink', response)
          } catch (error) {
            console.log("Error downloading image:", error);
          }
        }

        const loadProgress = async (meeting) => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/load_answer_state`, 
                meeting,
                {
                    headers: { 'Authorization': `Bearer ${idToken}` }
                });
                localStorage.setItem('answerState', JSON.stringify(response.data));
            } catch (error) {
                console.error('Error loading progress', error);
            } finally {
                setIsLoading(false);
            }
        };

        const getUsername = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/user_name`,
                    {
                        headers: {
                            Authorization: `Bearer ${idToken}`
                        }
                    }
                )
                setUsername(response.data.username);
            } catch (error) {
                console.log("Error getting username:", error)
            }
        }
    
        fetchMeeting().then(meeting => {
            getDownloadLink(meeting);
            loadProgress(meeting);
        })
        getUsername();
      }, []);

    return (
        <Flex flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh" p={5} w="100vw" mx="auto" borderRadius="md" boxShadow="md">
            
            {/* Logos and Title */}
            <HeaderBar 
                title={`${currentMeeting.company}  ${currentMeeting.title}`}
                imageLink={imageLink}
            />

            {/* Welcome Message */}
            <Box mt={5} mb={10} textAlign="left" width="lg">
                <Text fontSize="xl">{t('Welcome')} {username}, </Text>
                {currentMeeting.answered ?
                <Text>
                {t('Welcome and closing - paragraph 1')} <br />
                <br />
                {t('Welcome and closing - paragraph 2')} <br />
                <br />
                {t('Warm regards,')} <br />
                De Nieuwe Commissaris team
                </Text>
                :
                <Text>
                {t('Welcome to the self-evaluation - paragraph 1')} <br />
                <br />
                {t('Welcome and closing - paragraph 1')} <br />
                <br />
                {t('Welcome and closing - paragraph 2')} <br />
                <br />
                {t('Warm regards,')} <br />
                De Nieuwe Commissaris team
                </Text>
                }
            </Box>

            <Spacer/>

            {/* Go to Evaluation Button */}
            <Button colorScheme="blue" isLoading={isLoading} isDisabled={isLoading} onClick={handleGoToEvaluation}>
                {t('Go to Evaluation')}
            </Button>

        </Flex>
    );
}

export default WelcomeScreen;