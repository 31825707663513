import { 
    Button, 
    Text,
    Tabs,
    TabList,
    Tab,
    TabPanel,
    TabPanels,
    Heading,
    VStack,
    Box,
    Link,
    useTheme
} from '@chakra-ui/react';
import Meetings from './Meetings'
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import theme from './theme';
import WelcomeScreen from './WelcomeScreen';
import HeaderBar from './components/HeaderBar';

function HomePage() {
    
    const idToken = localStorage.getItem('idToken');
    const navigate = useNavigate();
    const admin = JSON.parse(localStorage.getItem("admin"));
    const theme = useTheme();
    const bgColor = theme.styles.global["html, body"].bg;
    const { t } = useTranslation();

    useEffect(() => {
        localStorage.removeItem("currentMeeting");
    }, []);

    const addMeeting = () => {
        navigate("/add-meeting");
    };

    const goToEditQuestions = () => {
        navigate("/edit-question-bank");
    }

    return (
        <div>
            {admin ? 
            <Box>
                <Box px={5} pt={5} mb={-5} position="sticky" top="0" zIndex="1000" bg={bgColor}>
                    <HeaderBar title="Admin Mode" imageLink={null}/>
                </Box>
                <Tabs>
                    <TabList display="flex" justifyContent="center" position="sticky" top="70px" zIndex="999" bg={bgColor}>
                        <Tab>{t('Create/Edit')}</Tab>
                        <Tab>{t('Active Evaluations')}</Tab>
                        <Tab>{t('Settings')}</Tab>
                    </TabList>
                
                    <TabPanels display="flex" justifyContent="center">
                        {/* Edit Meeting Page */}
                        <TabPanel width="3xl">
                            <Box p={5} shadow="md" borderWidth="1px" borderRadius="md" borderColor="blue.500" width="100%">
                                <Text>
                                    {t('Here you can create new meetings, or see all the meetings that still need to be edited.')}
                                </Text>
                            </Box>
                            <Button marginY="10px" width="100%" colorScheme="teal" variant="solid" onClick={addMeeting}>
                                {t('Create New Meeting')}
                            </Button>
                            <Meetings active={false}/>
                        </TabPanel>

                        {/* Active Meetings Page */}
                        <TabPanel width="3xl">
                            <Box p={5} shadow="md" borderWidth="1px" borderRadius="md" borderColor="blue.500" width="100%">
                                <Text>
                                    {t('Here you can see all meetings that are currently active. You can see responses, delete meetings, or download reports.')}
                                </Text>
                            </Box>
                            <Meetings active={true}/>
                        </TabPanel>

                        {/* Settings Page */}
                        <TabPanel width="3xl">
                            <Button width="100%" marginX="24px" marginTop="12px" onClick={goToEditQuestions}>{t('Edit Questions')}</Button>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
            :
            <WelcomeScreen/>
            // <Box w="2xl" mx="auto">
            //     <Box
            //     m="24px"
            //     p={5}
            //     borderWidth="1px"
            //     borderRadius="lg"
            //     shadow="md"
            //     w="100%"
            //     maxW="md"
            //     mx="auto"
            //     >
            //         <Heading size='md'>Welcome {username}</Heading>
            //         <Text mt="6px">
            //             Below are all the meetings you've been added to. If you have any issues using the software you can <Link href="mailto:support@example.com" color="blue.500">email support by clicking here.</Link>
            //         </Text>
            //     </Box>
            //     <Meetings active={true}/>
            // </Box>
            }
        </div>
    );
}

export default HomePage