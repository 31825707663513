import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Center,
  Heading,
  Text,
  VStack
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const BeginEvaluation = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const currentMeeting = JSON.parse(localStorage.getItem('currentMeeting'));
  const { company } = currentMeeting;
  const idToken = localStorage.getItem('idToken');
  const { t } = useTranslation();

  useEffect(() => {
    const loadProgress = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/load_answer_state`, 
        currentMeeting,
        {
          headers: { 'Authorization': `Bearer ${idToken}` }
        });
        localStorage.setItem('answerState', JSON.stringify(response.data));

      } catch (error) {
        console.error('Error loading progress', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadProgress();
  });

  const handleBegin = () => {
    navigate('/answer-question');
   };

  return (
    <Center h="100vh" w="100%">
      <VStack spacing={6}>
        <Heading textAlign="center">{company} - {t('Board Evaluation')}</Heading>
        
        <Box
          p={5}
          borderWidth="1px"
          borderRadius="lg"
          shadow="md"
          w="100%"
          maxW="md"
        >
          <Text>
            {t('Welcome to this online board evaluation questionnaire. You will be asked 10 questions, and it should take around 30 minutes. If at any point you leave this page your answers will be saved.')}
          </Text>
        </Box>
        
        <Button
          colorScheme="blue"
          size="lg"
          borderRadius="full"
          onClick={handleBegin}
          isLoading={isLoading}
          isDisabled={isLoading}
        >
          {t('Begin')}
        </Button>
      </VStack>
    </Center>
  );
};

export default BeginEvaluation;
