import { Flex, Box, Button, VStack, Checkbox, Text, Input, HStack, Accordion, AccordionItem, AccordionButton, AccordionPanel } from '@chakra-ui/react'; // Import the Input component
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import HeaderBar from './components/HeaderBar';

const PickQuestions = () => {
    const [questionBank, setQuestionBank] = useState({});
    const [selectedQuestions, setSelectedQuestions] = useState({});
    const [searchTerm, setSearchTerm] = useState(''); // New state for the search term
    const navigate = useNavigate();
    const currentMeeting = JSON.parse(localStorage.getItem('currentMeeting')) || {};
    const idToken = localStorage.getItem('idToken');
    const { t } = useTranslation();

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/get_question_bank`, {
                    headers: {
                        Authorization: `Bearer ${idToken}`
                    }
                })
                setQuestionBank(response.data.questions)
            } catch (error) {
                console.log("Error fetching question bank:", error)
            }
        };
        
        fetchQuestions();
    }, []);

    const toggleQuestion = (id, question) => {
        setSelectedQuestions(prev => {
            if (prev[id]) {
                const { [id]: _, ...newState } = prev;
                return newState;
            } else {
                return { ...prev, [id]: { question: question.question, topic: question.topic, open_ended: question.open_ended } };
            }
        });
        console.log(selectedQuestions)
    };

    // Function to group questions by topic
    const groupByTopic = (questions) => {
        return questions.reduce((topics, [id, question]) => {
            const topic = question.topic || "No Topic";  // Assuming each question object now has a 'topic' property
            if (!topics[topic]) {
                topics[topic] = [];
            }
            topics[topic].push([id, question]);
            return topics;
        }, {});
    };

    const topicGroups = groupByTopic(Object.entries(questionBank));

    const handleBack = () => {
        navigate('/');
    };

    const handleAddQuestions = () => {
        const setMeetingQuestions = async () => {
            const postData = {
                meeting: currentMeeting,
                meeting_questions: selectedQuestions
            };
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/set_meeting_questions`, 
                postData,
                {
                    headers: {
                        Authorization: `Bearer ${idToken}`
                    }
                })
            } catch (error) {
                console.log("Error setting questions:", error)
            }
        };

        setMeetingQuestions();

        navigate('/add-participants');
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="flex-end" height="100%" px={5} pt={5} w="100%" mx="auto">
            <Box width="100%" mb={-5}>
                <HeaderBar
                    title={`${t('Choose questions for')} ${currentMeeting.company}`}
                    imageLink={null}
                >
                    <Flex marginRight="14px" justifyContent="center" alignItems="center">
                        <Button onClick={handleBack}>{t('Back')}</Button>
                    </Flex>
                </HeaderBar>
            </Box>
            <Box p={4} width="80%" mx="auto">
                <HStack>
                    
                    
                    {/* Search Input */}
                    <Input
                        placeholder={t('Search for questions...')}
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        mb={4}
                    />
                </HStack>
                
                <VStack 
                    spacing={4} 
                    overflowY="auto" 
                    height="calc(100vh - 216px)"
                    css={{
                        "&::-webkit-scrollbar": {
                            display: "none",
                        },
                        msOverflowStyle: "none",
                        scrollbarWidth: "none",
                    }}
                >
                    <Accordion allowToggle width="100%">
                        {Object.entries(topicGroups)
                            .map(([topic, questions]) => (
                                <AccordionItem key={topic} width="100%">
                                    <h2>
                                        <AccordionButton width="100%">
                                            <Box flex="1" textAlign="left">
                                                {topic}
                                            </Box>
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        {questions.filter(([id, question]) => question.question.includes(searchTerm)) // Adjusted filtering based on new structure
                                            .map(([id, question]) => (
                                                <Box key={id} width="100%" p={4} borderWidth="1px" borderRadius="lg">
                                                    <Text mb={1}>Q{id}: {question.open_ended ? ' (open ended)' : ''} {question.question}</Text>
                                                    <Checkbox onChange={() => toggleQuestion(id, question)}>{t('Select')}</Checkbox> {/* Adjusted to pass 'question' property */}
                                                </Box>
                                            ))}
                                    </AccordionPanel>
                                </AccordionItem>
                            ))}
                    </Accordion>
                </VStack>
                
                <Box display="flex" justifyContent="space-between" mt={4}>
                    <Text>{t('Selected')}: {Object.keys(selectedQuestions).length}</Text>
                    <Button onClick={handleAddQuestions}>{t('Next')}</Button>
                </Box>
            </Box>

        </Box>
    );
}

export default PickQuestions;
