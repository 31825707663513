// Meetings.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Heading,
  Text,
  Button,
  Collapse,
  Flex,
  Spacer,
  useDisclosure,
  VStack,
  Image,
  Container,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  useToast,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogContent
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { storage as firebaseStorage } from './firebase';
import { ref, getDownloadURL } from 'firebase/storage';

const MeetingCard = ({ meeting, setDeleteTrigger }) => {
  const [imageLink, setImageLink] = useState(null);
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const [meetingToDelete, setMeetingToDelete] = useState(null);
  const { isOpen, onToggle } = useDisclosure();
  const navigate = useNavigate();
  const admin = JSON.parse(localStorage.getItem("admin"));
  const dateObj = new Date(meeting.date); // converts from a timestamp to a date object
  const dateString = `${dateObj.getDate()}-${dateObj.getMonth()+1}-${dateObj.getFullYear()}`;
  const idToken = localStorage.getItem('idToken');
  const onClose = () => setIsAlertDialogOpen(false);
  const cancelRef = React.useRef();
  const toast = useToast();
  const { t } = useTranslation();

  useEffect(() => {
    const getDownloadLink = async () => {
      try {
        const imageRef = ref(firebaseStorage, meeting.logo_url);
        const response = await getDownloadURL(imageRef);
        setImageLink(response)
      } catch (error) {
        console.log("Error downloading image:", error);
      }
    }

    getDownloadLink();
  }, []);

  const handleDownloadExcelReport = async () => {
    toast({
      title: "Report being created.",
      description: "Download should automatically start in a few seconds. Please don't close this page.",
      status: "info",
      duration: 5000,
      isClosable: true,
    });
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/generate_excel_report`,
        meeting,
        {
          responseType: 'blob',
          headers: { 'Authorization': `Bearer ${idToken}` }
        }
      )

      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', 'report.xlsx'); // or any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.log("error generating report:", error);
    }
  }

  const handleDownloadReportPDF = async (participant_type) => {
    toast({
      title: "Report being created.",
      description: "Download should automatically start in a few seconds. Please don't close this page.",
      status: "info",
      duration: 5000,
      isClosable: true,
    });
    try {
      // Build the URL based on whether participant_type is defined
      let url = `${process.env.REACT_APP_API_URL}/generate_report`
      if (participant_type) {
        url = `${process.env.REACT_APP_API_URL}/generate_report?participant_type=${participant_type}`
      }
      const response = await axios.post(url,
        meeting,
        {
          responseType: 'blob',
          headers: { 'Authorization': `Bearer ${idToken}` }
        }
      )

      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', 'report.pdf'); // or any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.log("error generating report:", error);
    }
  }

  const handleDownloadReportDOCX = async (participant_type) => {
    toast({
      title: "Report being created.",
      description: "Download should automatically start in a few seconds. Please don't close this page.",
      status: "info",
      duration: 5000,
      isClosable: true,
    });
    try {
      // Build the URL based on whether participant_type is defined
      let url = `${process.env.REACT_APP_API_URL}/generate_report?word=true`
      if (participant_type) {
        url = `${process.env.REACT_APP_API_URL}/generate_report?participant_type=${participant_type}&word=true`
      }
      const response = await axios.post(url,
        meeting,
        {
          responseType: 'blob',
          headers: { 'Authorization': `Bearer ${idToken}` }
        }
      )

      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', 'report.docx'); // or any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.log("error generating report:", error);
    }
  }

  const handleDeleteMeeting = () => {
    setIsAlertDialogOpen(true);
  }

  const handleConfirmDelete = async () => {
    onClose();
    
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/delete_meeting`,
        meeting,
        {
          headers: { 'Authorization': `Bearer ${idToken}` }
        }
      )
    } catch (error) {
      console.log("error generating report:", error);
    }

    setDeleteTrigger(prev => !prev)
  }

  // set current meeting to clicked meeting and go to the add-sections page
  const goToEditMeeting = () => {
    localStorage.setItem("currentMeeting", JSON.stringify(meeting));
    // if questions have already been added go to the add users section, otherwise go to the pick questions section
    if (meeting.added_questions) {
      navigate('/add-participants');
    } else {
      navigate('/pick-questions');
    }
  }

  // set current meeting to clicked meeting and then to the begin evaluation page
  const goToBeginEvaluation = () => {
    localStorage.setItem("currentMeeting", JSON.stringify(meeting));
    navigate('/meeting-overview'); 
  };

  const goToEvaluationOverview = () => {
    localStorage.setItem("currentMeeting", JSON.stringify(meeting));
    localStorage.setItem("logoLink", imageLink);
    navigate('/meeting-overview'); 
  };

  const goToParticipantDetails = () => {
    localStorage.setItem("currentMeeting", JSON.stringify(meeting));
    navigate('/participant-details');
  };

  return (
    <Box marginY="24px" p={5} shadow="md" borderWidth={2} flex="1" borderRadius="xl" borderColor="blue.500">
      <Flex flexDirection="row" alignItems="center">
        <VStack align="start">
          <Heading fontSize="lg" m={0} p={0}><Text as="span" fontWeight="normal">{t('Meeting Title:')} </Text><Text as="span" fontWeight="bold">{meeting.title}</Text></Heading>
          <Text m={0} p={0}>{t('Company:')} <Text as="span" fontWeight="bold">{meeting.company}</Text></Text>
          <Text m={0} p={0}>{t('To be completed by:')} {dateObj.toDateString()}</Text>
        </VStack>
        <Spacer />
        <Image src={imageLink} ignoreFallback height="50px" mr={4}  />
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <Text mt="1em">{meeting.description}</Text>
      </Collapse>
      <Flex mt="1em">
        {meeting.description !== '' &&
        <Button size="sm" onClick={onToggle} variant='link'>
          {isOpen ? 'Hide Details' : 'Show Details'}
        </Button> }
        <Spacer />
        {
          meeting.active ?
            (meeting.answered || admin) ? (
              <div>
                <Button colorScheme="green" variant="solid" onClick={goToEvaluationOverview} mr="12px">
                  {t('See Responses')}
                </Button>
                { admin &&
                <Menu>
                  <MenuButton
                    as={Button}
                    aria-label="Options"
                    variant="outline"
                    rightIcon={<HamburgerIcon />}
                  >
                    {t('Options')}
                  </MenuButton>
                  <MenuList>
                    <MenuGroup title={t('Download Spider Report - pdf')}>
                      <MenuItem onClick={() => handleDownloadReportPDF(undefined)}>All</MenuItem>
                      <MenuItem onClick={() => handleDownloadReportPDF("SB")}>SB</MenuItem>
                      <MenuItem onClick={() => handleDownloadReportPDF("EB")}>EB</MenuItem>
                      <MenuItem onClick={() => handleDownloadReportPDF("Other")}>Other</MenuItem>
                    </MenuGroup>
                    <MenuGroup title={t('Download Spider Report - docx')}>
                      <MenuItem onClick={() => handleDownloadReportDOCX(undefined)}>All</MenuItem>
                      <MenuItem onClick={() => handleDownloadReportDOCX("SB")}>SB</MenuItem>
                      <MenuItem onClick={() => handleDownloadReportDOCX("EB")}>EB</MenuItem>
                      <MenuItem onClick={() => handleDownloadReportDOCX("Other")}>Other</MenuItem>
                    </MenuGroup>
                    <MenuGroup title={t('More Options')}>
                      <MenuItem onClick={handleDownloadExcelReport}>{t('Download Full Report')}</MenuItem>
                      <MenuItem onClick={goToParticipantDetails}>{t('See Participants & Passwords')}</MenuItem>
                      <MenuItem onClick={handleDeleteMeeting}>{t('Delete Meeting')}</MenuItem>
                    </MenuGroup>
                  </MenuList>
                </Menu>
                }
              </div>
            ) : (
              <Button colorScheme="blue" variant="solid" onClick={goToBeginEvaluation}>
                {t('Answer')}
              </Button>
            )
            :
            <div>
              <Button colorScheme="teal" variant="solid" onClick={goToEditMeeting} mr="12px">
                {t('Edit')}
              </Button>
              <Button colorScheme="red" variant="solid" onClick={handleDeleteMeeting}>
                {t('Delete')}
              </Button>
            </div>
        }
      </Flex>

      {/* alert diaglog to ask if the user is sure */}
      <AlertDialog
          isOpen={isAlertDialogOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
      >
          <AlertDialogOverlay>
              <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                      Delete Meeting
                  </AlertDialogHeader>

                  <AlertDialogBody>
                      Are you sure? This action can't be undone.
                  </AlertDialogBody>

                  <AlertDialogFooter>
                      <Button ref={cancelRef} onClick={onClose}>
                          Cancel
                      </Button>
                      <Button colorScheme="red" onClick={handleConfirmDelete} ml={3}>
                          Delete
                      </Button>
                  </AlertDialogFooter>
              </AlertDialogContent>
          </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}

const Meetings = ({ active }) => {
  const [meetings, setMeetings] = useState([]);
  const [deleteTrigger, setDeleteTrigger] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const idToken = localStorage.getItem('idToken');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/get_meetings?active=${active}`, {
          headers: { 'Authorization': `Bearer ${idToken}` }
        });
        setMeetings(response.data.meetings);
      } catch (error) {
        console.error('Error fetching meetings', error);
      }
    };

    fetchData();
  }, [deleteTrigger]);

  return (
    <Box d="flex" flexDirection="column" alignItems="center">
      {meetings.map(meeting => (
        <MeetingCard key={meeting.title} meeting={meeting} setDeleteTrigger={setDeleteTrigger} />
      ))}
    </Box>
  );
}

export default Meetings;
