import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import { ChakraProvider, ThemeProvider } from '@chakra-ui/react';
import theme from './theme'; 
import i18n from './i18n';
import Login from './Login';
import HomePage from './HomePage';
import AddMeeting from './AddMeeting'
import AddSections from './AddSections';
import AddQuestions from './AddQuestions';
import BeginEvaluation from './BeginEvaluation';
import AnswerQuestion from './AnswerQuestion';
import SubmitAnswer from './SubmitAnswer';
import MeetingOverview from './MeetingOverview';
import QuestionResponses from './QuestionResponses';
import PickQuestions from './PickQuestions';
import AddParticipants from './AddParticipants';
import MeetingCreated from './MeetingCreated';
import SetPassword from './SetPassword';
import EditQuestionBank from './EditQuestionBank';
import ParticipantDetails from './ParticipantDetails';
import './i18n';

function App() {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/login" element={isLoggedIn ? <Navigate to="/" /> : <Login onLogin={() => setIsLoggedIn(true)} />} />
          <Route path="/set-password" element={isLoggedIn ? <Navigate to="/" /> : <SetPassword />} />
          <Route path="/" element={isLoggedIn ? <HomePage /> : <Navigate to="/login" />} />
          <Route path="/add-meeting" element={isLoggedIn ? <AddMeeting /> : <Navigate to="/login" />} />
          <Route path="/add-sections" element={isLoggedIn ? <AddSections /> : <Navigate to="/login" />} />
          <Route path="/add-questions" element={isLoggedIn ? <AddQuestions /> : <Navigate to="/login" />} />
          <Route path="/begin-evaluation" element={isLoggedIn ? <BeginEvaluation /> : <Navigate to="/login" />} />
          <Route path="/answer-question" element={isLoggedIn ? <AnswerQuestion /> : <Navigate to="/login" />} />
          <Route path="/submit-answer" element={isLoggedIn ? <SubmitAnswer /> : <Navigate to="/login" />} />
          <Route path="/meeting-overview" element={isLoggedIn ? <MeetingOverview /> : <Navigate to="/login" />} />
          <Route path="/question-responses" element={isLoggedIn ? <QuestionResponses /> : <Navigate to="/login" />} />
          <Route path="/pick-questions" element={isLoggedIn ? <PickQuestions /> : <Navigate to="/login" />} />
          <Route path="/add-participants" element={isLoggedIn ? <AddParticipants /> : <Navigate to="/login" />} />
          <Route path="/meeting-created" element={isLoggedIn ? <MeetingCreated /> : <Navigate to="/login" />} />
          <Route path="/edit-question-bank" element={isLoggedIn ? <EditQuestionBank /> : <Navigate to="/login" />} />
          <Route path="/participant-details" element={isLoggedIn ? <ParticipantDetails /> : <Navigate to="/login" />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;

