import { Box, Flex, Image, Text, useTheme, Spacer, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

function HeaderBar({children, title, imageLink, }) {
    const staticLogoPath = "/De_Nieuwe_Commissaris_logo.png";
    const theme = useTheme();
    const bgColor = theme.styles.global["html, body"].bg;
    const { i18n } = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };

    const isCurrentLanguage = (language) => {
        return i18n.language === language;
    };

    return (
        <Flex width="100%" alignItems="center" mb={5} position="sticky" top="0" zIndex="1000" bg={bgColor}>
            {children}
            <Image 
                src={staticLogoPath} 
                alt="Static Logo" 
                boxSize="50px" 
                mr={5}
            />
            <Image 
                src={imageLink}     
                ignoreFallback 
                alt="Dynamic Logo" 
                maxHeight="50px" 
                mr={5}
                display={imageLink?"inline":"none"}
            />

            {/* This is your absolute positioned title */}
            <Box position="absolute" width="100%" textAlign="center" top="50%" transform="translateY(-50%)" pointerEvents="none">
                <Text fontSize="2xl" fontWeight="bold">{title}</Text>
            </Box>

            <Spacer />

            {/* Language Selector Buttons */}
            <Button textColor={isCurrentLanguage('en') ? 'brand.500' : 'inherit'} variant="ghost" onClick={() => changeLanguage('en')} mr={1}>EN</Button>
            <Button textColor={isCurrentLanguage('nl') ? 'brand.500' : 'inherit'} variant="ghost" onClick={() => changeLanguage('nl')}>NL</Button>
        </Flex>
    )
}

export default HeaderBar