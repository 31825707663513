import React, { useEffect, useState } from 'react';
import {
  Box,
  Heading,
  VStack,
  Button,
  Text,
  useToast,
  HStack,
  Accordion, 
  AccordionItem, 
  AccordionButton, 
  AccordionPanel,
  Flex,
  Spacer
} from '@chakra-ui/react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HeaderBar from './components/HeaderBar';


// TODO: perhaps prevent people from seeing this page, or further answers if they are not an admin or haven't answered the meeting
const MeetingOverview = () => {
  const [questions, setQuestions] = useState([]);
  const toast = useToast();
  const idToken = localStorage.getItem('idToken');
  const currentMeeting = JSON.parse(localStorage.getItem('currentMeeting'))
  const admin = JSON.parse(localStorage.getItem("admin"));
  const logoLink = localStorage.getItem('logoLink');
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    console.log(logoLink);
    // Fetch all questions on component mount
    const fetchQuestions = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/get_all_questions`,
            currentMeeting,
            {
                headers: { 'Authorization': `Bearer ${idToken}` }
            }
        );
        setQuestions(response.data.questions);
      } catch (error) {
        toast({
          title: "Error fetching questions.",
          status: "error",
          duration: 3000,
          isClosable: true
        });
      }
    };

    fetchQuestions();
  }, [toast]);

  const handleSeeResponses = (topic) => {
    // find the current topic index
    const topicIndex = currentMeeting.topics.indexOf(topic)

    // Set the currentQuestion in local storage
    localStorage.setItem('currentTopicIndex', topicIndex);
    // Navigate to the question-responses page
    navigate('/question-responses');
  };

  const handleGoToTopic = (topic) => {
    // Set the currentQuestion in local storage
    localStorage.setItem('currentTopicIndex', String(currentMeeting.topics.indexOf(topic)));

    // decide where to navigate based on whether the questoins have been answered already or not
    if (currentMeeting.answered || admin) {
      // Navigate to the see-responses page
      navigate('/question-responses');
    } else {
      // Navigate to the question-responses page
      navigate('/answer-question');
    }
    

  }

  // Function to group questions by topic
  const groupByTopic = (questions) => {
      return questions.reduce((topics, [id, question]) => {
          const topic = question.topic || "No Topic";  // Assuming each question object now has a 'topic' property
          if (!topics[topic]) {
              topics[topic] = [];
          }
          topics[topic].push([id, question]);
          return topics;
      }, {});
  };

  const topicGroups = groupByTopic(Object.entries(questions));

  console.log(topicGroups);
  console.log(currentMeeting.topics);

  const handleBack = () => {
    navigate('/');
  }

  return (
    <Box p={5}>
      <HeaderBar
        title={`${currentMeeting.company} - ${currentMeeting.title}`}
        imageLink={logoLink}
      >
        <Flex marginRight="14px" justifyContent="center" alignItems="center">
          <Button onClick={handleBack}>{t('Back')}</Button>
        </Flex>
      </HeaderBar>
      <VStack 
          spacing={4} 
          width="80%"
          overflowY="auto" 
          mx="auto"
          mt={10}
          height="80vh"
          css={{
              "&::-webkit-scrollbar": {
                  display: "none",
              },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
          }}
      >
          <Box p={5} shadow="md" borderWidth="2px" borderRadius="md" width="100%" borderColor="blue.500">
              <Text>
                  {t("This is an overview of all the questions in the evaluation. Click on a topic below to expand it and see the questions within that topic. When you are ready you can click 'Go To This Topic' to jump to that topic.")}
              </Text>
          </Box>
          <Accordion allowToggle width="100%">
              {currentMeeting.topics
                  .map((topic) => (
                      <AccordionItem key={topic} width="100%">
                          <h2>
                              <AccordionButton width="100%">
                                  <HStack width="100%">
                                    <Box fontSize="lg" flex="1" textAlign="left">
                                        {topic}
                                    </Box>
                                    <Button fontSize="sm" variant="ghost" onClick={() => handleGoToTopic(topic)}>{t('Go To This Topic')}</Button>
                                  </HStack>
                              </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            {topicGroups[topic]?.map(([id, q]) => ( // map through questions
                              <Box 
                                key={q.index} 
                                d="flex" 
                                justifyContent="space-between" 
                                alignItems="center" 
                                p={4} 
                                width="100%"
                              >
                                <Text noOfLines={1}>
                                  {q.index}. {q.text}
                                </Text>
                              </Box>
                            ))}
                          </AccordionPanel>
                      </AccordionItem>
                  ))}
          </Accordion>
      </VStack>
    </Box>
  );
};

export default MeetingOverview;
