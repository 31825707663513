import React, { useEffect, useState, useRef } from 'react';
import {
  Box, 
  Button, 
  VStack, 
  Text, 
  Heading, 
  HStack, 
  Input,
  useToast,
  Spacer,
  Flex,
  Badge,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel
} from '@chakra-ui/react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HeaderBar from './components/HeaderBar';

const QuestionResponses = () => {
    const [questions, setQuestions] = useState([]);
    const [openedItems, setOpenedItems] = useState([]);
    const [currentTopicIndex, setCurrentTopicIndex] = useState(Number(localStorage.getItem('currentTopicIndex') || '0'));
    const [questionResponses, setQuestionResponses] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [boxHeight, setBoxHeight] = useState(400);
    const [boxWidth, setBoxWidth] = useState(750);
    const boxRef = useRef(null);
    const idToken = localStorage.getItem('idToken');
    const currentMeeting = JSON.parse(localStorage.getItem('currentMeeting'))
    const logoLink = localStorage.getItem('logoLink');
    const toast = useToast();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
      // first close all accordion tabs
      setOpenedItems([]);
      
      const fetchQuestions = async () => {
        try {
          const currentTopic = currentMeeting.topics[currentTopicIndex]
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/get_questions_by_topic?topic=${currentTopic}`,
          currentMeeting,
          {
              headers: { 'Authorization': `Bearer ${idToken}` }
          }
          );
          setQuestions(response.data);
          return response.data;
        } catch (error) {
          console.error('Error fetching questions', error);
        }
      };

      const fetchResponses = async (fetchedQuestions) => {
        const tempQuestionResponses = {};
        for (let question of fetchedQuestions) {
          try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/get_responses?question_number=${question.index}`,
              currentMeeting,
              {
                  headers: { 'Authorization': `Bearer ${idToken}` }
              }
            );
            tempQuestionResponses[question.index] = response.data.responses;
          } catch (error) {
            console.error('Error fetching responses', error);
          }
        }
        setQuestionResponses(tempQuestionResponses);
      };
  
      setIsLoading(true);
      fetchQuestions().then(fetchQuestions => {
        setIsLoading(false);
        fetchResponses(fetchQuestions);
      });
    }, [currentTopicIndex]);

    const handleBackToOverview = () => {
      navigate('/meeting-overview');
    }
  
    const handleNavigation = (direction) => {
      if (boxRef.current) {
        setBoxHeight(boxRef.current.offsetHeight);
        setBoxWidth(boxRef.current.offsetWidth);
        console.log(boxHeight)
      }
      if (direction === 'next') {
        if (currentTopicIndex < currentMeeting.topics.length - 1) {
          setCurrentTopicIndex(currentTopicIndex + 1);
        } else {
          // TODO: change this to the right section
          navigate('/submit-answer');
        }
      } else if (direction === 'back' && currentTopicIndex > 0) {
        setCurrentTopicIndex(currentTopicIndex - 1);
      }
    };

    const handleBack = () => {
      navigate("/meeting-overview");
    }

    const ResponseCard = ({ res, questionIndex, openEnded }) => {
      const [replyText, setReplyText] = useState('');
      const [replies, setReplies] = useState([]);
      const [isOpen, setIsOpen] = useState(false);
      const [isSending, setIsSending] = useState(false);

      const fetchReplies = async () => {
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/get_replies?question_number=${questionIndex}&response_uid=${res.uid}`,
            currentMeeting,
            {
                headers: { 'Authorization': `Bearer ${idToken}` }
            }
          );
          setReplies(response.data.replies)
        } catch (error) {
          toast({
            title: "Error fetching replies.",
            status: "error",
            duration: 3000,
            isClosable: true
          });
        }
      }

      const handleShowReplies = async () => {
        await fetchReplies();
        setIsOpen(true);
      }

      const sendReply = async () => {
        setIsSending(true);
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/add_reply?question_number=${questionIndex}&response_uid=${res.uid}`,
            {
              meeting: currentMeeting,
              reply: {
                "text": replyText
              }
            },
            {
                headers: { 'Authorization': `Bearer ${idToken}` }
            }
          );
          if (!response.data.success && response.data.max_replies_reached) {
            toast({
              title: `You are allowed at most ${response.data.max_replies} per question`,
              status: "error",
              duration: 3000,
              isClosable: true
            })
          }
        } catch (error) {
          console.log(error)
          toast({
            title: "Error fetching replies.",
            status: "error",
            duration: 3000,
            isClosable: true
          });
        }
        setReplyText('');
        setIsSending(false);
        await fetchReplies();
      }
    
      return (
        <VStack spacing={1.5} p={4} borderWidth="1px" borderRadius="md" shadow="sm" w="100%">
          <HStack justifyContent="flex-start" w="100%">
            <Text fontWeight="bold">{res.name}</Text>
            <Spacer/>
            { /* score display */ }
            { !openEnded &&
              <Box>
                {[1, 2, 3, 4, 5].map((number) => (
                  <Text
                    key={number}
                    fontSize={number === res.score ? '2xl' : 'sm'} // Larger font size if number is the score
                    fontWeight={number === res.score ? 'bold' : 'normal'} // Bold if number is the score
                    display="inline-block"
                    mr={2} // Margin right for spacing
                  >
                    {number}
                  </Text>
                ))}
              </Box>
            }
          </HStack>
          <Text width="100%" textAlign="left">{res.answer}</Text>
          <Button variant="link" onClick={handleShowReplies}>
            {t('Show Replies')} ({res.replies})
          </Button>
          {isOpen && replies.map((reply, idx) => (
            <Box key={idx} borderWidth="1px" borderRadius="md" p={2} shadow="sm" w="100%">
              <Text fontWeight="bold">{reply.name}</Text>
              <Text mt={1}>{reply.text}</Text>
            </Box>
          ))}
          <HStack w="100%">
            <Input 
              variant="filled" 
              disabled={isSending}
              placeholder={t('Write your reply...')} 
              value={replyText}
              onChange={e => setReplyText(e.target.value)}
            />
            <Button 
              colorScheme="blue" 
              onClick={sendReply}
              isLoading={isSending}
              isDisabled={isSending}
            >
              {t('Reply')}
            </Button>
          </HStack>
        </VStack>
      );
    };
  
    return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="flex-end" height="100%" px={5} pt={5} w="100%" mx="auto">
        <HeaderBar
          title={`${currentMeeting.company} - ${currentMeeting.title}`}
          imageLink={logoLink}
        >
          <Flex marginRight="14px" justifyContent="center" alignItems="center">
            <Button onClick={handleBackToOverview}>{t('Back')}</Button>
          </Flex>
        </HeaderBar>
        <Flex width="100%">
          {/* Sidebar for topics */}
          <VStack align="start" spacing={1} p={5} minW="200px" maxW="300px" borderWidth="1px" borderRadius="md" mr={5}>
            <Text fontSize="lg" fontWeight="bold" mb={4}>{t('Navigate to Topics')}</Text>
            
            {currentMeeting.topics.map((topic, index) => (
              <Box 
                w="100%"  // Full width
                p={3} 
                borderRadius="md" 
                bg={currentTopicIndex === index ? 'blue.500' : 'transparent'} 
                color={currentTopicIndex === index ? 'white' : 'black'} 
                _hover={{ bg: 'gray.200', cursor: 'pointer' }} 
                key={index}
                onClick={() => setCurrentTopicIndex(index)}
                isTruncated
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {topic}
              </Box>
            ))}
          </VStack>

          {/* main content */}
          <Box flex="1">
            <VStack spacing={4} p={5} width="90%" height="100%" overflowY="auto">
              <Accordion index={openedItems} onChange={setOpenedItems} allowToggle width="100%">
                {
                  questions.map((question, index) => (
                    <AccordionItem key={index} width="100%">
                      <h2>
                        <AccordionButton width="100%">
                            <HStack>
                              <Box fontSize="lg" flex="1" textAlign="left">
                                  {question.text}
                              </Box>
                            </HStack>
                        </AccordionButton>
                    </h2>
                      <AccordionPanel pb={4}>
                      {questionResponses[question.index]?.map((res, index) => (
                        <ResponseCard key={res.uid} res={res} questionIndex={question.index} openEnded={question.open_ended} />
                      ))}
                      </AccordionPanel>
                    </AccordionItem>
                  ))
                }
              </Accordion>

              <HStack width="80%" justifyContent="space-between" pt={5}>
                {currentTopicIndex > 0 && <Button onClick={() => handleNavigation('back')}>{t('Back')}</Button>}
                <Box></Box>
                {currentTopicIndex < currentMeeting.topics.length - 1 && <Button onClick={() => handleNavigation('next')}>{t('Next')}</Button>}
              </HStack>
            </VStack>
          </Box>
        </Flex>
      </Box>
    );
  };
  
  export default QuestionResponses;
  