import { React, useEffect, useState } from 'react';
import { Tooltip, Table, Thead, Tbody, Tr, Th, Td, Box, Button, Text, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import HeaderBar from './components/HeaderBar';
import axios from 'axios';

const MeetingCreated = () => {
  const [loginDetails, setLoginDetails] = useState([]);
  const navigate = useNavigate();
  const idToken = localStorage.getItem('idToken');
  const currentMeeting = JSON.parse(localStorage.getItem('currentMeeting'))
  const { t } = useTranslation();

  useEffect(() => {
    const retrieveLoginDetails = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/get_participant_details`, 
        currentMeeting,
        {
          headers: { 'Authorization': `Bearer ${idToken}` }
        });
        console.log(response.data.login_details);
        setLoginDetails(response.data.login_details);
      } catch (error) {
        console.error('Error while loading participant details', error);
      } 
    };

    retrieveLoginDetails();
  }, []);

  const handleCopyLink = (link) => {
    navigator.clipboard.writeText(link);
    // You can add some logic here to notify the user that the link was copied successfully
  };

  const handleDone = () => {
    navigate('/');
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="flex-end" minHeight="100%" px={5} pt={5} w="100%">
      <HeaderBar
        title="Evaluation Successfully Created"
        imageLink={null}
      />
      <Box p={4} textAlign="center" width="80%">

        <Box borderWidth="1px" borderRadius="lg" padding={5} mb={4}>
          <Text mb={4}>
            {t('You have successfully created your evaluation. It can now be found on your')} <b>{t('active evaluations')}</b> {t('page. You can send the link below to participants to allow them to log in.')}
          </Text>

          <Table size="sm">
            <Thead>
              <Tr>
                <Th>{t('Email')}</Th>
                <Th>{t('Login Link')}</Th>
                <Th>{t('Copy')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {loginDetails.map((ld, index) => (
                <Tr key={index}>
                  <Td>{ld.email}</Td>
                  <Td isTruncated maxWidth="200px">
                    <Tooltip label={ld.login_link} placement="top" hasArrow>
                      {ld.login_link}
                    </Tooltip>
                  </Td>
                  <Td>
                    <Button onClick={() => handleCopyLink(ld.login_link)} size="sm">
                      <CopyIcon />
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>

        </Box>

        <Button onClick={handleDone}>{t('Done')}</Button>
      </Box>
    </Box>
  );
}

export default MeetingCreated;
